import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap, Power2, TweenMax } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "../components/layout"
import ContactForm from "../library/contact-form"
import SEO from "../components/seo"

//TODO: add back download link
// import downloadButton from "../../static/images/download-app-store.svg"
import mainProjectImage from "../../static/images/tallyist-tally-projects.png"
import mainBudgetImage from "../../static/images/tallyist-simple-budget.jpg"
import mainLoanImage from "../../static/images/tallyist-loans.jpg"

gsap.registerPlugin(ScrollTrigger)

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px auto;
  visibility: hidden;
`

const IndexPage = () => {
  let mainWrapper = useRef(null)
  let tl = new gsap.timeline({ paused: true })

  const Iphone = ({ imageSrc, copy }) => {
    return (
      <div className="iphone-section">
        <div className="iphone">
          <div className="iphone-small-round-top" />
          <div className="iphone-speaker" />
          <img src={imageSrc} className="iphone-screenshot" />
          <div className="iphone-button" />
        </div>
        {copy && <div className="iphone-about">{copy}</div>}
      </div>
    )
  }
  useEffect(() => {
    TweenMax.to(mainWrapper, 0, { visibility: "visible" })
    tl.staggerFrom(
      mainWrapper.children,
      1,
      {
        duration: 4,
        opacity: 0,
        yPercent: -10,
        ease: Power2.easeInOut,

        scrollTrigger: {
          trigger: mainWrapper,
          scrub: 0.5,
          start: "top 90%",
          end: "bottom 25%",
          // events: onEnter onLeave onEnterBack onLeaveBack
          toggleActions: "restart pause reverse reset",
        },
      },
      0.15
    )
  })

  return (
    <Layout>
      <SEO title="Keep track of your money" />

      <div className={"page-header home"}>
        <h1>Keep track of your money</h1>
        <p className="page-header__content">
          Free your mind by keeping a running tally of your expenses in a todo
          list format<br></br>
          {/* <img className="btn-download" src={downloadButton} /> */}
        </p>
        <h2>Coming soon.</h2>
      </div>
      <Container ref={(el) => (mainWrapper = el)}>
        <Iphone
          imageSrc={mainProjectImage}
          copy="Keep track on renovation expenses"
        />
        <Iphone imageSrc={mainBudgetImage} copy="Use it for weekly budgets" />
        <Iphone imageSrc={mainLoanImage} copy="Personal loans" />
      </Container>

      {/* <div className={"call-to-action"}>
        <div className={"container"}>
          <div className={"call-to-action__content"}>
            <h2>Sign up for free</h2>
            <p>
              Sign up and start increasing the productivity of your business
              with HiStaff.
            </p>
          </div>

          <div className={"button"}>
            <a href="https://app.histaff.io" target={"_blank"}>
              Get Started
            </a>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default IndexPage
